<template>
    <header>
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
            <div class="flex flex-1 justify-end gap-x-16">
                <a
                    :href="route('blog.posts.index')"
                    class="text-sm font-semibold leading-6 text-white"
                    >Blog</a
                >

                <a :href="route('app.login')" class="text-sm font-semibold leading-6 text-white"
                    >Log in <span aria-hidden="true">&rarr;</span></a
                >
            </div>
        </nav>
    </header>
</template>
